import React, { useEffect } from "react";
import Footer from "../../Shared/Footer/Footer";
import Navigation from "../../Shared/Navigation/Navigation";
import hideMenu from "../../../utilities/hideMenu";
import aboutMe from "../../../assets/images/about_me.png";
import AboutParticles from "../AboutParticles/AboutParticles";

const About = () => {
    useEffect(() => {
        hideMenu();
    }, []);

    return (
        <>
            <Navigation />
            <div className="main">
                <div className="container my-5 my-xl-0">
                    <div className="row align-items-center home-banner my-lg-5 my-xl-0">
                        <div className="col-lg-6">
                            <img
                                src={aboutMe}
                                className="portfolioPic mx-auto d-block lift-up-2"
                                alt=""
                            />
                        </div>
                        <div className="col-lg-6">
                            <div className="my-lg-0 mx-3 px-3 text-justify">
                                <h2 className="lift-up-2">
                                    <span className="fs-4">About</span>{" "}
                                    <span className="text-blue">
                                        Jawad Bin Mobin Akib
                                    </span>
                                </h2>
                                <p className="lift-up-2">
                                    I'm a dedicated MERN Stack Web Developer.
                                    Web development is my first love. I love to
                                    explore more things about this sector.
                                </p>
                                <p className="lift-up-2">
                                    I have skill in this sector and I am so
                                    dedicated and passionate to my work. I have
                                    2+ full stack, 8+ front end projects. I can
                                    design pixel-perfect and develop dynamic
                                    website as per requirements. Despite having
                                    skill in Full Stack, I love to work in
                                    front-end more than back-end.
                                </p>
                                <a
                                    href="https://www.linkedin.com/in/jbmakib"
                                    download="Resume-Of-Jawad-Bin-Mobin"
                                    className="folio-primary-button rounded-pill mt-3 lift-up-2"
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    Hire Me
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <AboutParticles />
            </div>
            <Footer />
        </>
    );
};

export default About;
