import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navigation.css";
import profileImg from "../../../assets/images/profile_img.jpg";

const Navigation = () => {
    const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

    const handleBurgerClick = () => {
        setIsMobileMenuActive(!isMobileMenuActive);
        document.body.classList.toggle("mobile-nav-active");
    };
    return (
        <header id="header">
            <div className="d-flex flex-column">
                <div className="profile">
                    <img
                        src={profileImg}
                        alt="Owner"
                        className="img-fluid rounded-circle"
                    />
                    <h1 className="text-light">
                        <NavLink to="/">Jawad Bin Mobin</NavLink>
                    </h1>
                    <div className="social-links mt-3 text-center">
                        <a
                            href="https://github.com/jbmakib"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fab fa-github"></i>
                        </a>
                        <a
                            href="https://linkedin.com/in/jbmakib"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fab fa-linkedin"></i>
                        </a>
                        <a
                            href="https://www.facebook.com/jbmakib.page"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <i className="fab fa-facebook"></i>
                        </a>
                    </div>
                </div>
                <nav className="nav-menu pb-xl-5 mb-xl-4">
                    <ul>
                        <li>
                            <NavLink to="/" exact activeClassName="active">
                                <i className="fas fa-home"></i>{" "}
                                <span>Home</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/about" activeClassName="active">
                                <i className="fas fa-user"></i>{" "}
                                <span>About</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/blogs" activeClassName="active">
                                <i className="fas fa-pen-square"></i>{" "}
                                <span>Blogs</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/projects" activeClassName="active">
                                <i className="fas fa-briefcase"></i>{" "}
                                <span>Projects</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact">
                                <i className="fas fa-envelope"></i>{" "}
                                <span>Contact</span>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
                <button
                    type="button"
                    onClick={handleBurgerClick}
                    className="mobile-nav-toggle d-xl-none"
                >
                    <i
                        className={`fas ${
                            isMobileMenuActive ? "fa-times" : "fa-bars"
                        }`}
                    ></i>
                </button>
            </div>
        </header>
    );
};

export default Navigation;
