import React, { useEffect } from "react";
import Navigation from "../../Shared/Navigation/Navigation";
import Banner from "../Banner/Banner";
import Footer from "../../Shared/Footer/Footer";
import hideMenu from "../../../utilities/hideMenu";

const Home = () => {
    useEffect(() => {
        hideMenu();
    }, []);

    return (
        <>
            <Navigation />
            <div className="main">
                <Banner />
            </div>
            <Footer />
        </>
    );
};

export default Home;
