import React from "react";
import Particles from "react-tsparticles";

const HomeParticles = () => {
    const particlesInit = (main) => {
        // console.log(main);
        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    };
    const particlesLoaded = (container) => {
        // console.log(container);
    };

    return (
        <Particles
            id="homeParticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                fpsLimit: 60,
                background: {
                    color: "#000",
                },
                interactivity: {
                    events: {
                        onClick: { enable: true, mode: "push" },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                            parallax: { enable: false, force: 60, smooth: 10 },
                        },
                        resize: true,
                    },
                    modes: {
                        push: { quantity: 4 },
                        repulse: { distance: 100, duration: 0.4 },
                    },
                },
                particles: {
                    color: { value: "#ffffff" },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: "out",
                        random: false,
                        speed: 2,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 80,
                    },
                    opacity: {
                        animation: {
                            enable: true,
                            speed: 0.05,
                            sync: true,
                            startValue: "max",
                            count: 1,
                            destroy: "min",
                        },
                        value: {
                            min: 0,
                            max: 0.5,
                        },
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
            }}
        />
    );
};

export default HomeParticles;
