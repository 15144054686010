import React from "react";

const ProjectDetails = ({
    projectDetails: { name, img, details, features, technology, code, live },
}) => {
    return (
        <div
            className="modal fade text-dark"
            id="projectDetails"
            tabIndex="-1"
            aria-labelledby="projectDetailsLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="projectDetailsLabel">
                            {name}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <img src={img} alt="" className="img-fluid" />
                        <p className="text-center my-2">{details}</p>
                        <h6>Key Features</h6>
                        <ul>
                            {features?.map((feature) => (
                                <li key={feature}>{feature}</li>
                            ))}
                        </ul>
                        <div className="text-center">
                            {technology?.map((single) => (
                                <span
                                    className="badge rounded-pill bg-danger mx-1"
                                    key={single}
                                >
                                    {single}
                                </span>
                            ))}
                        </div>
                        <div className="d-flex justify-content-evenly mt-3 flex-column flex-sm-row">
                            <a
                                href={live}
                                target="_blank"
                                className="btn btn-outline-primary rounded-pill my-1 my-sm-0"
                                rel="noreferrer"
                            >
                                <i className="fas fa-eye"></i> Preview
                            </a>
                            {code?.client && (
                                <a
                                    href={code.client}
                                    target="_blank"
                                    className="btn btn-outline-primary rounded-pill my-1 my-sm-0"
                                    rel="noreferrer"
                                >
                                    <i className="fab fa-github"></i> Client
                                    Side
                                </a>
                            )}
                            {code?.server && (
                                <a
                                    href={code.server}
                                    target="_blank"
                                    className="btn btn-outline-primary rounded-pill my-1 my-sm-0"
                                    rel="noreferrer"
                                >
                                    <i className="fab fa-github"></i> Server
                                    Side
                                </a>
                            )}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="me-auto btn btn-outline-danger rounded-pill"
                            data-bs-dismiss="modal"
                        >
                            <i className="fas fa-times-circle"></i> Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDetails;
