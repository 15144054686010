import React, { useRef } from "react";
import ContactAction from "../ContactAction/ContactAction";
import emailjs from "emailjs-com";

const Form = () => {
    const form = useRef();
    emailjs.init({
        publicKey: `${process.env.REACT_APP_EMAIL_JS_USER_ID}`,
      });

    const handleSendMessage = (e) => {
        e.preventDefault();      
            emailjs.sendForm(
                `${process.env.REACT_APP_EMAIL_JS_SERVICE_ID}`,
                `${process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID}`,
                form.current
            )
            .then(
                (result) => {
                    console.log(result.text);
                },
                (error) => {
                    console.log(error.text);
                }
            );
        form.current.reset();
    };
    return (
        <div className="container px-xl-5 my-xl-5 my-3">
            <h1 className="fw-bold text-center mt-3 mb-5">
                Contact<span className="text-purple"> Us</span>
            </h1>
            <div className="g-4 row row-cols-lg-2 row-cols-1">
                <div className="col">
                    <h5 className="fw-bold">Get in touch</h5>
                    <p>
                        Have a question? Concern? Request? I’d love to hear from
                        you. Connect with us through the following ways.
                    </p>
                    <ContactAction icon="fa-phone-alt" title="Mobile">
                        <a
                            href="#"
                            className="text-decoration-none my-1"
                        >
                            <span>"hidden for security issues"</span>
                        </a>
                    </ContactAction>
                    <ContactAction icon="fa-map-marker-alt" title="Location">
                        <a
                            href="#"
                            target="_blank"
                            className="text-decoration-none my-1"
                            rel="noreferrer"
                        >
                            <span>CTG, Bangladesh</span>
                        </a>
                    </ContactAction>
                    <ContactAction icon="fa-envelope" title="Email">
                        <a
                            href="mailto:jbmakib@gmail.com"
                            className="text-decoration-none my-1"
                        >
                            <span>jbmakib@gmail.com</span>
                        </a>
                    </ContactAction>
                </div>
                <div className="col">
                    <h5 className="fw-bold">Message Us</h5>
                    <form
                        ref={form}
                        onSubmit={handleSendMessage}
                        className="text-dark"
                    >
                        <div className="mt-2 mb-md-3 row row-cols-md-2 row-cols-1">
                            <div className="form-floating col">
                                <input
                                    type="text"
                                    className="form-control mb-3 mb-md-0"
                                    id="floatingName"
                                    placeholder="Full Name"
                                    required
                                    name="name"
                                />
                                <label htmlFor="floatingName" className="ps-4">
                                    Full Name
                                </label>
                            </div>
                            <div className="form-floating col">
                                <input
                                    type="email"
                                    className="form-control mb-3 mb-md-0"
                                    id="floatingEmail"
                                    placeholder="name@example.com"
                                    required
                                    name="email"
                                />
                                <label htmlFor="floatingEmail" className="ps-4">
                                    Email
                                </label>
                            </div>
                        </div>
                        <div className="form-floating">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingSub"
                                placeholder="Subject"
                                required
                                name="subject"
                            />
                            <label htmlFor="floatingSub">Subject</label>
                        </div>
                        <div className="form-floating my-3">
                            <textarea
                                className="form-control"
                                placeholder="Leave a comment here"
                                id="floatingTextarea2"
                                required
                                style={{ height: "140px" }}
                                name="message"
                            ></textarea>
                            <label htmlFor="floatingTextarea2">
                                Message...
                            </label>
                        </div>
                        <button
                            className="folio-primary-button rounded-pill"
                            type="submit"
                        >
                            Send Message
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Form;
