import React, { useEffect } from "react";
import hideMenu from "../../../utilities/hideMenu";
import Footer from "../../Shared/Footer/Footer";
import Navigation from "../../Shared/Navigation/Navigation";

const Blogs = () => {
    useEffect(() => {
        hideMenu();
    }, []);

    return (
        <>
            <Navigation />
            <div className="main">
                <div className="container">
                    <h2 className="text-center">Blog is coming soon</h2>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Blogs;
