import React, { useEffect } from "react";
import Footer from "../../Shared/Footer/Footer";
import Navigation from "../../Shared/Navigation/Navigation";
import hideMenu from "../../../utilities/hideMenu";

const Skills = () => {
    useEffect(() => {
        hideMenu();
    }, []);

    return (
        <>
            <Navigation />
            <div className="main">
                <div className="container">
                    <h2>This page is in under construction</h2>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Skills;
