import React from "react";
import "./Project.css";

const Project = ({
    project,
    setProjectDetails,
    project: { name, img, live, type, details },
}) => {
    return (
        <div className="col">
            <div className="h-100 rounded-4 overflow-hidden card text-dark">
                <img
                    src={img}
                    className="card-img-top project-img"
                    alt="Project ScreenShot"
                />
                <div className="card-body">
                    <h5 className="card-title">{name}</h5>
                    <p className="card-text">
                        {name} is a {type}. It is {details}.
                    </p>
                    <a
                        href={live}
                        target="_blank"
                        className="btn btn-outline-primary rounded-pill me-3 my-2 my-sm-0"
                        rel="noreferrer"
                    >
                        <i className="fas fa-eye"></i> Preview
                    </a>
                    <button
                        className="btn btn-outline-primary rounded-pill"
                        data-bs-toggle="modal"
                        data-bs-target="#projectDetails"
                        onClick={() => setProjectDetails(project)}
                    >
                        <i className="fas fa-info-circle"></i> Details
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Project;
