import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import About from "./Pages/About/About/About";
import Blogs from "./Pages/Blogs/Blogs/Blogs";
import Contact from "./Pages/Contact/Contact/Contact";
import Home from "./Pages/Home/Home/Home";
import Projects from "./Pages/Projects/Projects/Projects";
import NotFound from "./Pages/Shared/NotFound/NotFound";
import Skills from "./Pages/Skills/Skills/Skills";

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/" exact>
                    <Home />
                </Route>
                <Route path="/about" exact>
                    <About />
                </Route>
                <Route path="/blogs" exact>
                    <Blogs />
                </Route>
                <Route path="/skills" exact>
                    <Skills />
                </Route>
                <Route path="/projects" exact>
                    <Projects />
                </Route>
                <Route path="/contact" exact>
                    <Contact />
                </Route>
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
