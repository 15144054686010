import React from "react";
import resume from "../../../assets/docs/resume.pdf";
import portfolioPic from "../../../assets/images/portfolio_pic.svg";
import HomeParticles from "../HomeParticles/HomeParticles";
import "./Banner.css";

const Banner = () => {
    return (
        <div className="container" id="homeParticles">
            <HomeParticles />
            <div className="row align-items-center home-banner my-lg-5 my-xl-0">
                <div className="col-lg-6">
                    <div className="home-left-div my-lg-0">
                        <h2 className="lift-up-2">Hi! I'm</h2>
                        <h1 className="lift-up-2">Jawad Bin Mobin Akib</h1>
                        <h4 className="lift-up-2">
                            A dedicated MERN Stack developer
                        </h4>
                        <a
                            href={resume}
                            download="Resume-Of-Jawad-Bin-Mobin"
                            className="folio-primary-button rounded-pill mt-3 lift-up-2"
                        >
                            Resume
                        </a>
                    </div>
                </div>
                <div className="col-lg-6">
                    <img
                        src={portfolioPic}
                        className="portfolioPic mx-auto d-block lift-up-2"
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
};

export default Banner;
