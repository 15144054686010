import React from "react";
import "./Footer.css";

const Footer = () => {
    return (
        <footer id="footer">
            <div className="container">
                <div className="credits">
                    &copy; Copyright 2021 |{" "}
                    <a
                        href="https://www.github.com/jbmakib"
                        target="_blank"
                        rel="noreferrer"
                    >
                        jbmakib
                    </a>
                    <span className="d-block">All right reserved</span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
