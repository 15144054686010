import React, { useState, useEffect } from "react";
import Footer from "../../Shared/Footer/Footer";
import Navigation from "../../Shared/Navigation/Navigation";
import Project from "../Project/Project";
import ProjectDetails from "../ProjectDetails/ProjectDetails";
import hideMenu from "../../../utilities/hideMenu";

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [projectDetails, setProjectDetails] = useState({});

    useEffect(() => {
        hideMenu();
        fetch("/projects.json")
            .then((res) => res.json())
            .then((data) => setProjects(data))
            .catch((err) => console.log(err.message));
    }, []);

    return (
        <>
            <Navigation />
            <div className="main">
                <div className="container my-3">
                    <h2 className="text-center my-3">
                        My Top Projects {projects.length}
                    </h2>
                    {projects.length === 0 ? (
                        <div className="text-center">
                            <div
                                className="spinner-border text-purple"
                                role="status"
                            ></div>
                        </div>
                    ) : (
                        <div className="g-5 mx-3 row row-cols-lg-3 row-cols-md-2 row-cols-1 justify-content-evenly">
                            {projects.map((project) => (
                                <Project
                                    key={project.id}
                                    project={project}
                                    setProjectDetails={setProjectDetails}
                                />
                            ))}
                        </div>
                    )}
                    <ProjectDetails projectDetails={projectDetails} />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Projects;
